import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;

    // モーダルの重なっば場合の挙動を変更
    // backdropが後ろのモーダルの下に行かないように
    $(document).on('show.bs.modal', '.modal', function () {
      var zIndex = 1040 + (10 * $('.modal:visible').length);
      $(this).css('z-index', zIndex);
      setTimeout(function() {
          $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
      }, 0);
    });
    // 重なったモーダルを閉じた後にモーダルのスクロールが効かなくなる場合の対処
    $(document).on('hidden.bs.modal', '.modal', function () {
      $('.modal:visible').length && $(document.body).addClass('modal-open');
    });

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  open() {
    $(this.element).modal("show");
  }
  close() {
    $(this.element).modal("hide");
  }
  contact() {
    if ($(".contact", $(this.element)).is(":visible")) {
      $(".tutorial_list", $(this.element)).css("display","block");
      $(".contact", $(this.element)).css("display","none");
    } else {
      $(".tutorial_list", $(this.element)).css("display","none");
      $(".contact", $(this.element)).css("display","block");
    }
  }
  
  benchmark_data_append(data_controller, urls, sub_sn_url_ids = []) {
    let _this = this;
    let element = $(".benchmark_modal")
    $(".carousel-inner", $(_this.element)).empty();
    if (urls.length > 5) {
      $(".caroucel-slide", element).css("display","inline-block");
    }
    urls.forEach( function (el,i) {
      let html = $(_this.benchmark_template(data_controller));
      html.find("label").html(el["fullname"]);
      html.find("label").attr("for", "sns_url_ids_" + el["id"]);
      html.find("input").attr("id", "sns_url_ids_" + el["id"])
      html.find("input").attr("value", el["id"])
      if (sub_sn_url_ids != []) {
        if ($.inArray(el["id"], sub_sn_url_ids) != -1) {
          html.find("input").attr("checked", "checked")
        }
      }
      $(".carousel-inner", element).append(html);
    })
    do {
      $(".carousel-inner", element).children("span:lt(5)").wrapAll('<div class="carousel-item"></div>')
    } while ($(".carousel-inner", element).children("span").length);
    $(".carousel-inner .carousel-item", element).first().addClass("active")
  }

  benchmark_template(data_controller) {
    let html = '<span>';
    html += '<input type="checkbox" name="sns_sub_url_ids" id="" value="" data-name="" data-target="'+data_controller+'.sub_sns_url_id" data-action="click->'+data_controller+'#onclick_check"></input>';
    html += '<label for=""></label>';
    html += '</span>'
    return html;
  }
  tutorial_data_append(data_controller, images=[], title, text=null) {
    let _this = this;
    $(".carousel-inner", $(_this.element)).empty();
    images.forEach(function(image) {
      $(".carousel-inner", $(_this.element)).append('<div class="carousel-item"><img src="'+image+'" /></div>');
    })
    $(".carousel-inner .carousel-item", $(_this.element)).first().addClass("active")
    if ($(".carousel-inner .carousel-item", $(_this.element)).length > 1) {
      $(".carousel-slide", $(_this.element)).css("display","block")
    }
    $(".title", $(_this.element)).html(title)
    $(".text", $(_this.element)).html(text)
  }

  add_tag_data_append(data_controller, hashtag_id) {
    let _this = this;
    $("#hashtag_id_select option", $(_this.element))
      .filter(function(){
        return $(this).val() == hashtag_id
    }).prop('selected', true).change()
  }
  append_instagram_preview_data(datas, list_type) {
    let _this = this;
    $(".preview_images", $(_this.element)).empty();
    datas.forEach(function(data, index) {
      let image = data.first_asset_thumb;
      let content_type = data.first_asset_content_type;
      if (image == null) return;

      let preview_modal_width = $(".instagram_preview_modal .modal-dialog").innerWidth();
      let width = "160px";
      let height = "214px";
      if (list_type == 0) {
        height = "214px";
      } else {
        height = "240px";
      }
      let element = $(".preview_images", $(_this.element));
      if (content_type == null || content_type == undefined) {
        element.append(`<div class='item' style='width:${width}; height:${height}'  data-key='"+index+"'><img src='${image}'></div>`)
        return
      }
      if (content_type.split("/")[0] == "video") {
        element.append(`<div class='item' style='width:${width}; height:${height}' data-key='"+index+"'><video style="height:${height}" src='${image}'></div>`)
      }else {
        element.append(`<div class='item' style='width:${width}; height:${height}' data-key='"+index+"'><img style="height:${height}" src='${image}'></div>`)
      }
      element.on('click', '.item', (event) =>{
        let key = $(event.currentTarget).data("key");
        if (data != datas[key]) return;
        _this.getControllerByIdentifier('schedule').modal_open();
        _this.getControllerByIdentifier('schedule').edit([datas[key]]);
        return;
      })
    })

  }

  append_modify_memos_data(data, year_month) {
    // 年月のフォーマットの成形
    const year = year_month.substr(0, 4);
    const month = year_month.substr(4, 2);

    const month_names = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month_abbreviation = month_names[parseInt(month) - 1];

    const formatted_year_month = `${month_abbreviation} ${year}`;
    $(".modify_memos_title").text(formatted_year_month)
  
    let _this = this;
    $(".modify_memos", $(_this.element)).empty();

    let element = $(".modify_memos", $(_this.element));

    if (data.length == 0) {
      element.append(`<div class='item-wrapper'>修正メモはありません。</div>`)
      return;
    } else {
      data.forEach(function(data, index) {
        let schedule_date = data.schedule_date;
        let memo_created_at = data.memo_created_at;
        let body = data.body;
        let status = data.status;
  
        element.append(`
          <div 
            class='item-wrapper' 
            style='${status === 1 ? 'text-decoration: line-through;' : ''}'
          >
            <div class='date'>${schedule_date}</div>
            <small class='date'>(${memo_created_at})</small>
            <div class='body'>${body}</div>
          </div>`)
      })
    }
  }
}