import { Controller } from "stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import feather from 'feather-icons'
import Swiper from 'swiper';
import Sortable from "sortablejs";
import dayjs from 'dayjs';
import JSZip from "jszip";
import Cropper from 'cropperjs';
import "cropperjs/dist/cropper.css";

const STATUSES = {
  EDIT: 0,
  DRAFT: 1,
  RESERVE: 2,
  COMPLETE: 3,
  FAIL: 4,
  PROOFREAD: 5
}
export default class extends Controller {
  static targets = ["sns_url_id","schedule_carousel", "schdule_carousel_slide","form", "event_id",
      "publish_images", "published_at", "schedule_status", "list_type", "upload_images", "memos",
      "disp_memos", "files", "body", "preview", "img_carousel",  "calendar" , "modal","instagram_preview_modal", "plan", "kind", 
      "input_count_tags","count_tags", "tags", "delete_modal", "close_modal", "delete_memo_modal", "selected_list_type", "add_user_tag_modal",
      "schedule_group_id", "disp_modify_memos", "modify_memos_modal", "previewBodyText", "location_information_search_modal", "location_name", "location_id", "location_information_delete_button",
      "thumbnail_set_modal", "preview_tab", "thumbnail_tab", "preview_content", "thumbnail_content", "thumbnail_video_wrapper", "thumbnail_video", "thumbnail_image_wrapper", "thumbnail_seconds", "thumbnail_image_checkbox",
      "image_crop_modal", "image_canvas", "sketch_image"
      ]; 
 
  initial_image_max = 3
  image_counts= [];
  memo_count  = 0;
  page = 0;
  update_assets = [];
  delete_assets = [];
  library_assets = [];
  update_memos  = [];
  usertags = [];
  modal_type = "new";
  open_date = null;
  schedule_save = false;
  plan = 1;
  kind_number;
  select_file_index;
  list_type = null;
  publish_errors = [];
  media_errors = [];
  select_schedule_group_id = []; 
  // 戦略画面切り替え用
  selected_list_type = null;
  spelling_check_words = [];
  image_crop_index = null;

  initialize() {
    var _this = this
    feather.replace()
    function formatIcon(opt) {
      let img = $(opt.element).attr("data-picture")
      let social_media_image = $(opt.element).attr("data-social_media_image");
      if (img != undefined) {
        return $("<span><img src=\" " + social_media_image + " \" class=\"url_icon left\"/><img src=\" " + img + " \" class=\"url_icon\"/> " + opt.text + "</span>")
      }
    }
    this.select2_url = $(this.sns_url_idTargets[0]).select2({
      tags: false,
      dropdownAutoWidth: true,
      tokenSeparators: [',', ' '],
      width: 'auto',
      templateResult: formatIcon,
      templateSelection: formatIcon
    });

    let calendarEl = this.calendarTargets[0]
    let calendaHeight = ($(window).width() >= 600 ) ? 760: $(window).width() * 0.81;
    this.calendar = new Calendar(calendarEl, {
      contentHeight: calendaHeight,
      locale: "en",
      firstDay: 1,
      themeSystem: "bootstrap",
      header: {
        left:   'prev',
        center: 'title',
        right:  'next'
      },
      plugins: [ dayGridPlugin, interactionPlugin ],
      views: {
        dayGridMonth: {
          titleFormat: { year: 'numeric', month: 'short' },
        }
      },
      editable: true,
      droppable: true,
      eventRender: function(info) {
        let imgUrl = info.event.extendedProps.img;
        // console.log(info.event.extendedProps);
        let listType =  info.event.extendedProps.list_type; 
        let scheduleGroupId = info.event.extendedProps.schedule_group_id;  

        if(imgUrl){
          let ele = $(info.el)
            .css("border-color", "transparent")
            .css("background-color", "transparent")
            // console.log(ele);
            if (imgUrl.match(/(jpeg|jpg|png|gif)$/i)) {
            // ele.html('<div class="calendar-content" data-list_type="'+listType+'" style="background: url('+imgUrl+'); background-size:contain; opacity:0.7"></div>')
            ele.html('<div class="calendar-content" data-schedule_group_id="'+scheduleGroupId+'" data-list_type="'+listType+'" style="background: url('+imgUrl+'?v='+new Date().getTime()+') center center; background-size:'+(imgUrl.includes('/cropped') ? 'cover' : 'contain')+'; background-repeat: no-repeat;"></div>');
          } else {
            ele.html('<div class="calendar-content" data-schedule_group_id="'+scheduleGroupId+'" data-list_type="'+listType+'"><video class="photo" playsinline src="'+imgUrl+'" /></div>');
          }
          if (info.event.extendedProps.cnt > 1) {
            $(info.el).append('<div class="folder"></div>')
          }
          if (info.event.extendedProps.reserve_status == true) {
            $(info.el).append('<div class="checkmark reserve"></div>')
          }
          if (info.event.extendedProps.proofread_status == true) {
            $(info.el).append('<div class="checkmark proofread"></div>')
          }
          if ((_this.get_selected_list_type === 0 || _this.get_selected_list_type === 1) && info.event.extendedProps.list_type != _this.get_selected_list_type) {
            ele.children(".calendar-content").css("opacity","0.2");
            $(info.el).append('<div class="mask"></div>')
          }
          if (_this.element.scheduleGroup.selected_group_list.length > 0){
            if (_this.element.scheduleGroup.selected_group_list[info.event.extendedProps.schedule_group_id] != undefined) {
              $(info.el).css("border", "2px solid " + _this.element.scheduleGroup.selected_group_list[info.event.extendedProps.schedule_group_id])
            } else {
              $(info.el).append('<div class="mask schedule_group_mask"></div>')
            }
          }
          if(info.event.extendedProps.modify_task) {
            $(info.el).append('<div class="red-circle"></div>')
          }
        } else {
          $(info.el).attr('data-schedule_group_id', scheduleGroupId);
          $(info.el).css("background-color",  _this.element.scheduleGroup.selected_group_list[info.event.extendedProps.schedule_group_id])
          if(info.event.extendedProps.modify_task) {
            $(info.el).append('<div class="red-circle"></div>')
          }
        }
    },
      eventClick: async function(info) {
        if (_this.plan == 1) {
          // _this.getControllerByIdentifier("modal").open();
          return;
        }
        _this.open_date = info.event.extendedProps.open_date;
        const event_data = await _this.form_change(info.event.extendedProps.open_date);
        _this.modal_open();

        setTimeout(function () {
          var param = _this.schedule_swiper.params
          _this.schedule_swiper.destroy( true, true );
          _this.schedule_swiper = new Swiper ('#schedule-carousel', param);
          _this.page = _this.schedule_swiper.activeIndex;
          for (let page = 0; page < event_data.length; page++) {
            _this.create_preview_thumnail(page);
          }
        },1000)
      },
      datesRender: function(info) {
        _this.element.checklist.exists_unchecked_checklist;
        _this.element.scheduleGroup.refresh_all();
      },
      dateClick: function(info) {
        // alert('clicked ' + info.dateStr);
        _this.new(info.dateStr);
        _this.create_preview_thumnail(this.page);
      },
      eventDrop: function(info) {
        _this.move_calendarEl(info);
      },
      dayRender: function(info) {
        let year_month = dayjs(_this.element.schedule.calendar_date).format("YYYYMM");
        $.ajax({
          url: `/client/api/v1/schedules/national_holiday?year_month=${year_month}`,
          type: "get",
          success: function (data) {
            _this.handle_day_render(data);
          }
        })
        
      },
    })

    this.swiper_params = {
      autoHeight: true,
      height: 1290,
      loop: false,
      autoplay:false,
      navigation: {
        nextEl: '.schedule-carousel-control-next-icon',
        prevEl: '.schedule-carousel-control-prev-icon',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullet',
        clickable: true
      },
      slidesPerView: 1,
      spaceBetween: 20,
      centeredSlides: true,
      allowTouchMove: false,
      on: {
        slideChange: function () {
          _this.page = _this.schedule_swiper.activeIndex
          _this.preview_render(_this.page)
        },
        init: function () {
        }
      }
    }
    this.schedule_swiper = new Swiper( '#schedule-carousel' , this.swiper_params);
    this.new_schedule_swiper = new Swiper( '#schedule-carousel' , this.swiper_params);

    window.onkeydown = function(e) {
      if (e.keyCode == 9)
        return false;
    }

    this.switch_display_schedule_group_list();
  }

  connect() {
    let _this = this;
    this.element[this.identifier] = this;

    $.ajax({
      type: "GET",
      url: "/client/api/v1/statuses/schedule",
      success: (data) => {
        _this.calendar_refetch();
        _this.plan = data["result"]["plan"]
        if (data["result"]["plan"] != 1) {
          _this.select2_url.on('select2:select', function () {
            let event = new Event('change', { bubbles: true })
            this.dispatchEvent(event);
          })
        _this.preview_render(null, 0);
        _this.file_input_render(_this.initial_image_max, 0);
      } else {
          let title = "投稿戦略の制作"
          let text = ""
          _this.getControllerByIdentifier("modal").tutorial_data_append("schedule", data["result"]["tutorial_images"], title, text);
          _this.getControllerByIdentifier("modal").open();
          return;
        }
      }
    })

  }

  calendar_refetch () {
    // this.calendar.eventSource.remove();
    this.calendar.getEventSources().forEach(function(ele, i ) {
      ele.remove();
    })
    if (this.selected_sns_url == undefined) return
    this.calendar.addEventSource({
      url: "/client/api/v1/schedules",
      method: "GET",
      extraParams: {
        sns_url_id: this.selected_sns_url.id,
        list_type: this.get_selected_list_type,
      }
    })

    this.calendar.render();
    this.element.scheduleGroup.refresh_all();
    this.element.scheduleGroup.checked_content_change_background();
    this.get_spelling_check_words();
  }

  url_select() {
    this.calendar_refetch();
    this.element.scheduleGroup.refresh_all();
    this.element.checklist.exists_unchecked_checklist;
  }

  deletefile(event) {
    let parent = $(event.target).parent();
    let index = parent.index();
    if ($("input",parent).attr("data-id") != undefined) {
      this.delete_assets[this.page].push($("input",parent).attr("data-id"))
    }
    this.validate_image_and_video_later();
    this.usertags[this.page][index] = null;
    this.usertags[this.page] = this.usertags[this.page].filter(Boolean);
    $(event.target).parent().remove();
    this.preview_file_render();
  }


  fileselect(event, file_url = null, parents = null, content_type) {
    let files = null;
    if (file_url != null ) {
      // libraryから
      this.disp_thumbnail(parents, file_url, content_type)
      this.preview_file_render();
    } else { 
      // ファイル選択から
      files = event.target.files
      let parent = (parents == null) ? $(event.target).parents(".publish_image") : parents;
      let parents = [];
      let _this = this;
      let publish_images = $(this.publish_imagesTargets[this.page])
      files.forEach((file, index) => {
        if (files.length > 1) {
          parents[index] = $($(event.target).parents(".publish_images")).children(".publish_image").find(".nothing").first().parents(".publish_image");
          if (parents[index].length == 0) {
            _this.add_publish_image(parents[index]);
            parents[index] = $($(event.target).parents(".publish_images")).children(".publish_image").find(".nothing").first().parents(".publish_image");
          }
          const fileListObject = new DataTransfer();
          fileListObject.items.add(file);
          const childInput = parents[index].find("input[type='file']");
          childInput[0].files = fileListObject.files;
        } 
        else {
          parents[index] = parent;
        }


        let thumbnail = $("img",parent);
        parents[index].removeAttr("data-library-select");
        parents[index].removeAttr("data-library-id");

        $("input", publish_images).each(function(index, el){
          if ($(el).attr("data-id") != undefined && $(event.target).attr("data-id") == $(el).attr("data-id") ) {
            _this.update_assets[_this.page].push({
              index: index,
              id : $(el).attr("data-id"),
            })
          }
        })

        const isVideo = file.type.split("/")[0] == "video";

        if (isVideo) {
          thumbnail = $("video",parent);
          $("img",parent).css("display","none")
          $("video",parent).css("display","block")
        } else {
          $("img",parent).css("display","block")
          $("video",parent).css("display","none")

        }
        let reader = new FileReader();
        reader.onload = function(e){
          const src = isVideo ? URL.createObjectURL(files[index]) : e.target.result;
          _this.disp_thumbnail(parents[index], src, files[index].type, files[index]);
          _this.preview_file_render();

          $(".feather, .nothing",parents[index]).css("display", "none");

        }
        reader.readAsDataURL(file);
        $(".nothing",parents[index]).remove();
        $(".select_upload").each((i, el) => {
          _this.element.schedule.close_file_select(el);
        })
      })
    }
  }

  async disp_thumbnail(parent, src, content_type="image", file=null, thumb_src=null, original_src=null) {
    let _this = this;
    let thumbnail = $("img",parent);
    if (content_type.split("/")[0] == "video") {
      thumbnail = $("video",parent);
      thumbnail.attr("playsinline", "playsinline");
      thumbnail.attr("muted", "muted");
    }
    thumbnail.css("display", "block")
    if (thumb_src == null) {
      thumbnail.attr("src",src)
    } else {
      thumbnail.attr("src",thumb_src)
    }
    if (file != null) {
      thumbnail.attr("data-url", file.name)
      thumbnail.attr("data-type", file.type)  
    } else {
      thumbnail.attr("data-url", src)
      thumbnail.attr("data-type", content_type) 
      thumbnail.attr("data-original-url", original_src) 
    }
    thumbnail.attr("data-target","schedule.upload_images")
    thumbnail.addClass("thumbnail");
    $(".nothing", parent).remove();
    $(".deletemark",parent).css("display","inline-block");
    this.validate_image_and_video_later();
  }

  // ファイルから選択の際はすぐにアスペクト比などが取得できないので、遅延させてから取得する
  async validate_image_and_video_later(){
    let _this = this;
    setTimeout(async () => {
      await this.validate_image_and_video();
    
      $(".media_errors_wrapper")[_this.page].innerHTML = "";
      $(".media_errors_wrapper")[_this.page].innerHTML = $.uniqueSort(_this.media_errors[_this.page]).join("<br>");
    }, 200);

  }

  async validate_image_and_video() {
    let _this = this;
    let publish_images = $(this.publish_imagesTargets[this.page]);
    let file_count = 0;
    let status = $(_this.schedule_statusTargets[_this.page]).data("status");
    this.media_errors[this.page] = [];
    await Promise.all(
      publish_images.children().map(async (i, el) => {
        let video = $('video',$(el));
        let image = $('img',$(el));
        let is_video = false;
        let width = null;
        let height = null;
        let src = (video.attr("src") != '' && video.attr("src") != undefined) ? video.attr("src") : image.attr("src");
        let file_size = await _this.get_filesize(src);
        let file_name = null;
        
        if (video.attr("src") != '' && video.attr("src") != undefined) {
          is_video = true;
          width = video[0].videoWidth;
          height = video[0].videoHeight;
          file_name = video.attr("data-url");
          // Instagramが許容する拡張子以外はエラーとする
          let allow_exts = new Array('mov', 'mp4');
          let ext = video.attr("src").split(".").at(-1).toLowerCase();
          if ($("input",$(el))[0].files.length > 0) {
            ext = $("input",$(el))[0].files[0].name.split(".").at(-1).toLowerCase();
          }
          if (allow_exts.indexOf(ext) == -1) {
            _this.media_errors[_this.page].push("動画ファイルの拡張子はmov, mp4である必要があります。");
          }
          file_count++;
        } else if(image.attr("src") != '' && image.attr("src") != undefined) {
          width = image[0].naturalWidth;
          height = image[0].naturalHeight;
          file_name = image.attr("data-url");
          file_count++;
        }
        _this.check_aspect(is_video, width, height, _this.media_errors[_this.page]);

        let is_size_valid = _this.check_filesize(is_video, file_size, _this.media_errors[_this.page]);
        let is_filename_valid = _this. check_filename_zenkaku(file_name, _this.media_errors[_this.page]);
        if (!is_size_valid || !is_filename_valid) {
          $(el).find('.image_form').css('border', '#d93616 3px solid');
        }
      })
    );
    if (_this.selected_list_type == '1' && file_count > 2) {
      _this.media_errors[_this.page].push("REELSは1枚のみ投稿できます。");
    } else if(file_count > 10){ 
      _this.media_errors[_this.page].push("最大の投稿枚数は10です。");
    }
    
    //  エラーがあると配信時にInstagramでのエラーが出るので、編集時でエラーがある場合は更新できないようにする
    _this.is_disabled_submit_button();
  }

  is_disabled_submit_button() {
    let _this = this;

    let status = $(_this.schedule_statusTargets[_this.page]).data("status");
    let is_invalid_image_and_video = false;

    // ①画像が3MG以上、②動画が100MB以上、③画像,動画のファイル名に全角が含まれている、④アスペクト比が正しくない場合
    if (_this.media_errors[_this.page] !== undefined) {
      is_invalid_image_and_video = $.uniqueSort(_this.media_errors[_this.page]).length > 0 && (status == STATUSES.EDIT || _this.modal_type == "new")
    }
    
    // ⑤ハッシュタグが31つ以上の場合
    let hashtags = $(this.bodyTargets[_this.page]).val().match(/[#＃][^\s#＃]+/gm);
    let is_invalid_hashtags = hashtags ? hashtags.length >= 31 : false;

    // ①〜⑤が1つ以上当てはまる場合「保存」ボタンを非活性にする
    if (is_invalid_image_and_video || is_invalid_hashtags) {
      $($(".submit_button > button")[_this.page]).prop("disabled", true);
    } else {
      $($(".submit_button > button")[_this.page]).prop("disabled", false);
    }
  }
  
  check_aspect( is_video, width, height, errors) {
    let _this = this;
    let aspect = width / height;
    if (this.selected_list_type == '1') {
      // reels
      if (aspect < 0.01 || aspect > 10) {
        errors.push("アスペクト比が正しくありません。 0.01:1 ~ 10:1の範囲で調整してください。");
      }
    } else {
      // feeds
      // if (is_video) {
      //   if (aspect < 0.8 || aspect > 1.91) {
      //     errors.push("アスペクト比が正しくありません。 4:5 ~ 1.91:1の範囲で調整してください。");
      //   }
      // } else{
      //   if (aspect < 0.4 || aspect > 1.91) {
      //     errors.push("アスペクト比が正しくありません。 4:5 ~ 1.91:1の範囲で調整してください。");
      //   }
      // }
    }
  }

  check_filesize(is_video, file_size, errors) {
    if (is_video) {
      if (file_size > 100 * 1024 * 1024) {
        errors.push("動画ファイルのサイズは100MB以下である必要があります。");
        return false;
      }
    } else {
      if (file_size > 3 * 1024 * 1024) {
        errors.push("画像ファイルのサイズは3MB以下である必要があります。");
        return false;
      }
    }
    return true;
  }

  check_filename_zenkaku(file_name=null, errors) {
    if (file_name === null || file_name === undefined) {
      return true
    }
    const ZENKAKU_PATTERN = /[^\x01-\x7E\xA1-\xDF]+/;
    if (decodeURIComponent(file_name).match(ZENKAKU_PATTERN)) {
      errors.push("ファイル名は全て半角である必要があります。");
      return false
    } else {
      return true
    }
  }

  async get_filesize(url) {
    if (url == undefined || url == '') {
      return;
    }
    const response = await fetch(url);
    const blob = await response.blob();
    const fileSize = blob.size;
    return fileSize;
  }

  add_plus_button(page) {
    if  (this.image_counts[page] < 10) {
      let template = $(".templates > .plus_image").clone();
      $(this.publish_imagesTargets[page]).append(template)
    }
  }

  add_publish_image(ele) {
    this.add_file_input(this.image_counts[this.page], this.page);
    this.usertags[this.page].push([]);
    $($(".publish_images")[this.page]).children(".plus_image").remove();
    this.add_plus_button(this.page);
  }

  file_input_render(num, page) {
    let add_count = this.image_counts[page]+num;
    for(let i=this.image_counts[page]; i< add_count ;i++) {
      this.add_file_input(i, page);
    }
    this.add_plus_button(page);
  }

  add_file_input(index,page=0,data=null) {
    let template = $(".templates > .publish_image").clone();
    $("label",template).attr("for", page + "-image-" + index +"");
    $("input",template).attr("id", page + "-image-" + index +"");
    $("input",template).attr("name","schedule[schedule_assets[]");

    // template.popover({
    //   placement: 'bottom',
    //   trigger: 'click',
    //   content  : () => {
    //     let upload_box = $("input",template).clone();
    //     upload_box.prop('disabled', false);
    //     return upload_box.css("display","block")
    //   },
    //   html     : true
    // });
    $(this.publish_imagesTargets[page]).append(template);

    this.image_counts[page]++;
    if (data != null) {
      $("input",template).attr("data-id",data.id);
      let parent = $("#"+ page + "-image-" + index +"").parents(".publish_image");
      let src = "";
      if ((data.crop_x === null && data.crop_y === null && data.crop_w === null && data.crop_h === null) || (data.crop_x === 0 && data.crop_y === 0 && data.crop_w === 0 && data.crop_h === 0)) {
        src = data.url
      } else {
        src = data.path.cropped.url
      }

      let thumb_src = "";
      if ((data.crop_x === null && data.crop_y === null && data.crop_w === null && data.crop_h === null) || (data.crop_x === 0 && data.crop_y === 0 && data.crop_w === 0 && data.crop_h === 0)) {
        thumb_src = data.path.jpeg.url
      } else {
        thumb_src = data.path.cropped.url
      }

      let original_src = data.url

      this.disp_thumbnail(parent, src, data.content_type, null, thumb_src, original_src)
      $(".download",template).attr("download",data.file_name);
      // $(".download",template).attr("href",data.url);
      $(".download",template).attr("href","/client/schedules/download?asset_id="+data.id);
      if (data.content_type === 'video/quicktime' || data.content_type === 'video/mp4') {
        $(".image_edit",template).css("display","none");
      }
    } else {
      $(".download",template).css("display","none");
      $(".image_edit",template).css("display","none");
    }
  }

  file_select_open(event) {
    let _this = this;
    let status = $(_this.schedule_statusTargets[_this.page]).data("status");
    if (status != STATUSES.EDIT && status != undefined) return;

    let parent = $(event.target).parents(".publish_image");
    if ($(".select_upload", parent).attr("data-open") == "true") {
      _this.close_file_select($(".select_upload", parent));
    } else {
      $(".select_upload").each((i, el) => {
        _this.close_file_select(el);
      })
      $(".select_upload", parent).css("display","block");
      $(".select_upload", parent).attr("data-open", "true");
    }
  }

  close_file_select(ele) {
    $(ele).css("display","none");
    $(ele).removeAttr("data-open");
  }

  add_memo(){
    $(".add_memo .input_memo").remove();
    let template = $(".templates > .input_memo").clone();
    $(this.disp_memosTargets[this.page]).parent().children(".add_memo").append(template);
  }

  change_text_keydown(element) {
    if ((this.prev_key === "Meta" || this.prev_key === "Control") && element.key == 'Enter'){
      this.append_memo(element);
    }
    this.prev_key = element.key;
  }

  append_memo(ele) {
    if (ele.target.value !== "") {
      this.create_memo(ele.target.value)
    }
  }

  create_memo(memo, data = null, page = null) {
    if (page == null) page = this.page;

    let same_memo_exist = false;
    $(".memo_view_area .memo", this.disp_memosTargets[page]).each((i, el) => {
      if ($("label",$(el)).text() == memo) {
        same_memo_exist = true
      }
    })
    // メモは同じものを登録できるようにする
    // if (same_memo_exist) return;

    this.memo_count++;
    let template = $(".templates > .memo").clone();
    $("label", template).attr("for", page + "-memo-" + this.memo_count + "");

    const urlPattern = /https?:\/\/[^\s]+/g;

    const memoHtml = memo.replace(urlPattern, function(match) {
        return `<a href="${match}" target="_blank">${match}</a>`;
    });

    $("label", template).html(`<div>${memoHtml}</div>`);

    $("input", template).attr("id", page + "-memo-" + this.memo_count + "");
    $("input", template).attr("name", page + "-memo-" + this.memo_count + "");
    $("input", template).attr("value", memo);
    $("input", template).prop('checked', false).change();
    
    if (data != null) {
      $("input",template).attr("data-id", data.id);
    }
    if (data != null && data.status == true) {
      $("input",template).prop('checked', true).change();
    }
    $(".memo_view_area", this.disp_memosTargets[page]).append(template);
    $(".input_memo",$(this.disp_memosTargets[page]).parent()).remove();
  }


  delete_memo(event) {
    let input = $(this.delete_memo_target).parents(".memo").children("input");
    if ($(input).attr("data-id")) {
      input.attr("data-delete", "1");
      input.parent().css("display","none");
    }else { 
      input.parent().remove();
    }
    this.delete_memo_confirm_close();
  }

  add_modify_memo(){
    $(".add_modify_memo .input_modify_memo").remove();
    let template = $(".templates > .input_modify_memo").clone();
    $(this.disp_memosTargets[this.page]).parent().children(".add_modify_memo").append(template);
  }

  change_text_keydown_modify_memo(element) {
    if ((this.prev_key === "Meta" || this.prev_key === "Control") && element.key == 'Enter'){
      this.append_modify_memo(element);
    }
    this.prev_key = element.key;
  }

  append_modify_memo(ele) {
    if (ele.target.value !== "") {
      this.create_modify_memo(ele.target.value)
    }
  }

  create_modify_memo(memo, data = null, page = null) {
    if (page == null) page = this.page;

    let same_memo_exist = false;
    $(".modify_memo_view_area .memo", this.disp_modify_memosTargets[page]).each((i, el) => {
      if ($("label",$(el)).text() == memo) {
        same_memo_exist = true
      }
    })
    // メモは同じものを登録できるようにする
    // if (same_memo_exist) return;

    this.memo_count++;
    let template = $(".templates > .memo").clone();
    $("label", template).attr("for", page + "-memo-" + this.memo_count + "");

    const urlPattern = /https?:\/\/[^\s]+/g;

    const memoHtml = memo.replace(urlPattern, function(match) {
        return `<a href="${match}" target="_blank">${match}</a>`;
    });

    $("label", template).html(`<div>${memoHtml}</div>`);

    $("input", template).attr("id", page + "-memo-" + this.memo_count + "");
    $("input", template).attr("name", page + "-memo-" + this.memo_count + "");
    $("input", template).attr("value", memo);
    $("input", template).prop('checked', false).change();
    
    if (data != null) {
      $("input",template).attr("data-id", data.id);
    }
    if (data != null && data.status == true) {
      $("input",template).prop('checked', true).change();
    }
    $(".modify_memo_view_area", this.disp_modify_memosTargets[page]).append(template);
    $(".input_modify_memo",$(this.disp_modify_memosTargets[page]).parent()).remove();
  }

  preview_render(el, index) {
    if (index == undefined) {
      index = this.page;
    }
    let preview = $(this.previewTargets[index])

    const selected_sns_url_ele = this.selected_sns_url;
    if (selected_sns_url_ele != undefined) {
      let icon = $(selected_sns_url_ele.element).data("picture")
      $(".preview-header__icon img", preview).attr("src", icon)
      $(".preview-header__name p", preview).text(selected_sns_url_ele.element.text)  
      $(".preview-body-text", preview).html(this.bodyTargets[index].value.replace(/\r?\n/g, '<br>'))
      this.disp_hashtag(index)
      this.selected_tag_add_class()
      this.disp_warning_line(index)
      this.switch_font_family()
    }
  }
  preview_file_clear(index=null) {
    if (index == null) {
      index = this.page;
    }
    let carouselEl = $(this.img_carouselTargets[index])
    $(".preview-carousel-inner",carouselEl).empty();
    $(".preview-carousel-indicators", carouselEl).empty();
  }
  preview_file_render(index=null, thumb_offset=null) {
    let _this = this;
    if (index == null) index = this.page;
    if (this.usertags[_this.page] == undefined) this.usertags[_this.page] = [];

    let carouselEl = $(this.img_carouselTargets[index])
    let publishImages = $(this.publish_imagesTargets[index])
    let thumbnailContent = $(this.thumbnail_contentTargets[index])
    let inner = $(".preview-carousel-inner",carouselEl)
    let indicator = $(".preview-carousel-indicators", carouselEl)
    $(".preview-carousel-inner, .preview-carousel-indicators",carouselEl).empty();
    
    $(".thumbnail", publishImages).each(function(i, el){
      if ($(el).attr("src")) {
        let active = "";
        if (i == 0) active = "active";
          if ($(el).attr("data-type").split("/")[0] == "video") {
            inner.append("<div class=\"preview-carousel-item carousel-item "+active+"\" data-action=\"click->schedule#open_user_tag_modal\"><video id=\"video\" playsinline controls src=\""+$(el).attr("src")+"\" data-page="+_this.page+" data-itemno="+i+"/></div>")

            // サムネイルタブ用の動画を表示
            thumbnailContent.find('.thumbnail-video-wrapper').empty();
            const video = document.createElement("video");
            video.src = $(el).attr("src");
            video.setAttribute("data-target", `schedule.thumbnail_video`);
            video.addEventListener("loadedmetadata", function() {
              video.currentTime = thumb_offset !== null ? thumb_offset/1000 : 0;
            });
            thumbnailContent.find('.thumbnail-video-wrapper').append(video);
          }else {
            inner.append("<div class=\"preview-carousel-item carousel-item "+active+"\" data-action=\"click->schedule#open_user_tag_modal\"><img src=\""+$(el).attr("src")+"\" data-page="+_this.page+" data-itemno="+i+" /><span class=\"tagcount\">"+ ((_this.usertags[_this.page][i] != undefined) ? `タグ：　${_this.usertags[_this.page][i].length}件` : ``)  +"</span></div>")
            
            // サムネイルタブ用の画像を表示
            if (thumbnailContent.find('.thumbnail-image-wrapper img').length === 0) {
              thumbnailContent.find('.thumbnail-image-wrapper').empty();
              const image = document.createElement("img");
              image.src = $(el).attr("src");
              thumbnailContent.find('.thumbnail-image-wrapper').append(image);
            }
          }
          indicator.append("<li data-target=\"#peview-carousel-"+index+"\" data-slide-to=\""+i+"\" class=\""+active+"\">")
        }
      if (_this.usertags[_this.page][i] == undefined) {
        _this.usertags[_this.page][i] = [];
      }
    })

    let prevNexTemplate = $(".templates > .preview-control").clone();
    $(".preview-carousel-inner").append(prevNexTemplate)
  }

  save(ele) {
    let formdata = new FormData($('.schedule-form').get(this.page));
    let _this = this;
    if (_this.schedule_save) {
      return;
    }
    _this.schedule_save = true;
    formdata.append('schedule[sns_url_id]', this.selected_sns_url.id);
    formdata.append('schedule[schedule_group_id]', this.selected_schedule_group_id);

    let update_assets = []
    $("input", this.publish_imagesTargets[this.page]).each(function(index, el){
      if (this.files[0] != undefined) {
        let files = this.files;
        let _this = this;
        Object.keys(files).forEach((i) => {
          update_assets.push({
            id: ( $(_this).attr("data-id") != undefined ) ? $(_this).attr("data-id") : null ,
            file : (files[i]) ? files[i].name : "",
            content_id: $(el).parents(".publish_image").attr("data-library-id"),
            position: index
          })
        })
      } else {
        update_assets.push({
          id: ( $(this).attr("data-id") != undefined ) ? $(this).attr("data-id") : null ,
          file : (this.files[0]) ? this.files[0].name : "",
          content_id: $(el).parents(".publish_image").attr("data-library-id"),
          position: index
        }) 
      }
    })

    let library_assets = [];
    $(this.publish_imagesTargets[this.page]).children(".publish_image").each(function(i, el){
      let input = $("input",$(el))[0]
      if (input.files[0] != undefined) {
        let files = input.files;
        Object.keys(files).forEach((i) => { 
            library_assets.push({
            file: files[i].name,
            content_id: null
          })
        })

      } else if ($(el).attr("data-library-id") != null)  {
        library_assets.push({
          file: null,
          content_id: $(el).attr("data-library-id")
        })
      }
    })


    formdata.append('schedule[update_assets]',JSON.stringify(update_assets))
    formdata.append('schedule[library_assets]', JSON.stringify(library_assets))
    if (_this.delete_assets[_this.page] != undefined){
      formdata.append('schedule[delete_assets]',JSON.stringify(_this.delete_assets[_this.page]))
    }
    formdata.append('schedule[user_tags]', JSON.stringify(_this.usertags[_this.page]));

    $("input", this.disp_memosTargets[this.page]).each(function(index, el){
      formdata.append('schedule[schedule_tasks[]',JSON.stringify({
        status: $(el).prop('checked') ? 1 : 0,
        body: $(el).val().replace(/^\d*\/\d*\s/, ''),
        id:   $(el).attr("data-id"),
        delete: $(el).attr("data-delete"),
      }))
    })

    $("input", this.disp_modify_memosTargets[this.page]).each(function(index, el){
      formdata.append('schedule[schedule_modify_tasks[]',JSON.stringify({
        status: $(el).prop('checked') ? 1 : 0,
        body: $(el).val().replace(/^\d*\/\d*\s/, ''),
        id:   $(el).attr("data-id"),
        delete: $(el).attr("data-delete"),
      }))
    })

    formdata.append('schedule[location_name]', $(this.location_nameTargets[this.page]).val())
    formdata.append('schedule[thumb_offset]', $(this.thumbnail_secondsTargets[this.page]).val())
    
    if (this.validate() == false) return;
    $.ajax({
      url: "/client/api/v1/schedules?t=" + new Date().getTime(),
      type: (_this.modal_type == "edit") ? 'patch' : "post",
      processData: false,
      contentType: false,
      data: formdata,
      success: function (data) {
        _this.calendar.refetchEvents();
        _this.element.checklist.exists_unchecked_checklist;

        let noticeController = _this.getControllerByIdentifier("notice")
        if (data["result"] == true) {
          noticeController.success("更新しました");
        } else {
          noticeController.warning("エラーが発生しました");
        }
        if (_this.modal_type == "new") {
          _this.modal_close();
        } else {
          // _this.form_change(_this.open_date)
          _this.instagram_preview_refresh(_this.list_type);
          _this.modal_close();
        }
        _this.element.scheduleGroup.checked_content_change_background();

        _this.schedule_save = false;
      }
    })
    this.page = 0;
  }

  remove() {
    let _this = this;
    let schedule_id = $(this.event_idTargets[this.page]).val();
    $.ajax({
      url: "/client/api/v1/schedules/"+schedule_id+"?sns_url_id="+this.selected_sns_url.id+"&t=" + new Date().getTime(),
      type: "delete",
      success: function (data) {
        _this.calendar.refetchEvents();
        let noticeController = _this.getControllerByIdentifier("notice")
        if (data["result"] == true) {
          noticeController.success("削除しました");
        }else { 
          noticeController.warning("エラーが発生しました");
        }
        _this.remove_confirm_close();
        _this.modal_close();
      }
    })

  }

  move_calendarEl(info) {
    let _this = this;
    let top_id = info.event.extendedProps.top_id;
    let list_type = info.event.extendedProps.list_type;
    let oldDate = new Date(info.event.extendedProps.top_open_at);
    let date = new Date(info.event.start);
    let move_date = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() + " " + oldDate.getHours().toString().padStart(2, '0') + ":" + oldDate.getMinutes().toString().padStart(2, '0') ;  
    let formData = new FormData();
    formData.append("schedule[id]", top_id);
    formData.append("schedule[open_at]", move_date);
    formData.append("schedule[sns_url_id", _this.selected_sns_url.id)
    formData.append("schedule[list_type", list_type)
    $.ajax({
      url: "/client/api/v1/schedules/move_date",
      data: formData,
      type: 'patch',
      processData: false,
      contentType: false,
      success: function (data) {
        _this.calendar.refetchEvents();
      }
    })

  }

  page_change(ele) {
    // this.page = this.schedule_swiper.activeIndex
    // console.log(this.page)
  }

  schedule_carousel_clear() {
    // console.log(_this.schedule_carouselTargets)

  }

  async form_change(open_date) {
    return new Promise((resolve, reject) => {
      let _this = this;
      this.open_date = open_date;
  
      $.ajax({
        url: "/client/api/v1/schedules/get_events?t=" + new Date().getTime(),
        type: 'get',
        data: {open_date: open_date, sns_url_id: this.selected_sns_url.id, list_type: this.get_selected_list_type},
        success: function (data) {
          _this.edit(data);
          resolve(data);
        },
        error: function (error) {
          reject(error);
        }
      });
    });
  }
  edit(data) {
    let _this = this;
    let carouselEl = $(_this.schedule_carouselTarget)
    // this.schedule_swiper.removeAllSlides();
    $(".schedule-carousel-inner",carouselEl).empty();
    this.modal_type = "edit";

    data.forEach(function(el, i ) {
      _this.update_assets[i] = [];
      _this.delete_assets[i] = [];
      _this.image_counts[i] = 0;
      _this.publish_errors[i] = [];
      _this.usertags[i] = [];
      _this.select_schedule_group_id[i] = el.schedule_group_id;
      let template = $(".templates > .schedule-carousel-item").clone();
      template.find('.publish_images').attr('id', `publish_images_${i}`);
      if (i == 0) {
        template.addClass("active")
      }
      let prevNexTemplate = $(".templates > .preview-control").clone();
      $(".schedule-carousel-inner",carouselEl).append(template);
      $(".post_title",_this.formTargets[i]).html("編集")
      $(_this.img_carouselTargets[i]).attr("id","peview-carousel-"+i)
      $(".carousel-slide", prevNexTemplate).css("display","block");
      $(".carousel-control-prev, .carousel-control-next",prevNexTemplate).attr("href","#peview-carousel-"+i)
      $(_this.img_carouselTargets[i]).append(prevNexTemplate);
      $(_this.event_idTargets[i]).val(el.id)
      $(_this.published_atTargets[i]).datetimepicker({})
      $(_this.published_atTargets[i]).val(el.open_at)
      $("option[value="+el.status+"]",$(_this.schedule_statusTargets[i])).attr("selected","selected").prop("selected", true)
      $(_this.schedule_statusTargets[i]).data("status",el.status);
      $(_this.schedule_statusTargets[i]).prepend($('option:selected',$(_this.schedule_statusTargets[i])))
      const $location_delete_button = $(_this.location_information_delete_buttonTargets[i]); 
      if (el.location_name === "" && el.location_id === null) { 
        $location_delete_button.hide(); 
      } else { 
        $location_delete_button.show(); 
      }

      if (el.thumb_offset === -1) { 
        // thumb_offsetが-1の時は、メディアの画像がサムネイルとして設定されている場合
        $(_this.thumbnail_video_wrapperTargets[i]).css("display","none");
        $(_this.thumbnail_image_wrapperTargets[i]).css("display","flex");
      } else { 
        $(_this.thumbnail_video_wrapperTargets[i]).css("display","flex"); 
        $(_this.thumbnail_image_wrapperTargets[i]).css("display","none");
      }
      $(".list_type")[i].children.forEach((radio_el, index) => {
         if (index % 2 == 0) {
          $(radio_el).attr("id", "list_type_" + i + "_" + $(radio_el).val());
          if (el.list_type == $(radio_el).val()) {
            $(radio_el).prop("checked", true);
          }
        } else {
          $(radio_el).attr("id", "list_type_" + i + "_" + $(radio_el).prev().val() + "_labradio_el");
          $(radio_el).attr("for", "list_type_" + i + "_" + $(radio_el).prev().val());
        }
      })

      $(".location_name_text").text(el.location_name)
      $(_this.location_nameTargets[i]).val(el.location_name)
      $(_this.location_idTargets[i]).val(el.location_id)
      $(_this.thumbnail_secondsTargets[i]).val(el.thumb_offset)

      // $("#list_type_" + i + "_"+ el.list_type, $(".list_type")[i]).prop('checked', true).change();
      // $("input",$(".schedule_group_select_table")[i])
      if (el.schedule_group_id > 0) {
        $("input",$(".schedule_group_select_table")[0]).filter(() => { return $(this).val() == el.schedule_group_id }).prop("checked", true)
      }
      $(_this.publish_imagesTargets[i]).empty();
      el.schedule_assets.forEach(function(assets, j){
        _this.add_file_input(j,i, assets );
        // usertagsを追加
        _this.usertags[i].push(assets.schedule_asset_user_tags);

        // data-crop-x-y-w-hに追加
        let publishImageContainer = $(_this.publish_imagesTargets[i]).find('.publish_image').eq(j);
        let cropDataElement = publishImageContainer.find('.crop_x_y_w_h');
        let cropData = {
          x: assets.crop_x,
          y: assets.crop_y,
          w: assets.crop_w,
          h: assets.crop_h
        };
        cropDataElement.attr('data-x-y-w-h', JSON.stringify(cropData));
      });
      if (el.schedule_assets.length < _this.initial_image_max) {
        _this.file_input_render(_this.initial_image_max - el.schedule_assets.length, i)
      } else {
        _this.add_plus_button(i)
      }
      el.schedule_tasks.forEach(function(tasks, j){
        _this.create_memo(tasks.body, tasks, i);
      })
      el.schedule_modify_tasks.forEach(function(tasks, j){
        _this.create_modify_memo(tasks.body, tasks, i);
      })
      $(_this.bodyTargets[i]).val(el.body)
      _this.preview_render(null, i)
      _this.preview_file_render(i, el.thumb_offset)
      _this.get_input_hashtags(i)
      _this.disp_hashtag(i)

      if (el.status != STATUSES.EDIT) {
        $(".deletemark",template).remove();
      }

      // どのステータスでもdisabled
      $("input[name='schedule[location_name]']").prop("disabled",true)

      // 「編集中」ステータスで、編集可能
      $("input[type=text], input[type=file], textarea, button[class=plus_image], .pickup_tag, #find_post_copy, #paste_schedule_body_text, #location_information_delete_butto", template).each(function(i,inputEl) {
        if (el.status != STATUSES.EDIT) {
          $(inputEl).prop("disabled",true)
        }else {
          $(inputEl).prop("disabled",false)
        }
      })

      // 「編集中/校了」ステータスで、編集可能
      $("input[name='schedule[open_at]']", template).each(function(i,inputEl) {
        if (el.status == STATUSES.PROOFREAD || el.status == STATUSES.EDIT) {
          $(inputEl).prop("disabled",false)
        } else {
          $(inputEl).prop("disabled",true)
        }
      })

      // 「編集中/確認中/校了/予約登録」ステータスで、編集可能
      $("#location_information_add_button, #thumbnail_offset_set_button, #thumbnail_cover_url_set_button", template).each(function(i,inputEl) {
        if (el.status === STATUSES.EDIT || el.status === STATUSES.DRAFT || el.status === STATUSES.PROOFREAD || el.status === STATUSES.RESERVE) {
          $(inputEl).prop("disabled",false)
        } else {
          $(inputEl).prop("disabled",true)
        }
      })

      // 「編集中/確認中/校了/予約登録」ステータスで、編集可能（aタグ）
      if (el.status === STATUSES.SUCCESS || el.status === STATUSES.FAIL) {
        $('.image_edit').css('pointer-events', 'none');
      } else {
        $('.image_edit').css('pointer-events', 'auto');
      }

      // 配信失敗のエラーがあれば表示する
      if (el.status == STATUSES.FAIL) {
        _this.display_publish_errors(el.publish_error_message, el.schedule_error_logs, i)
      } 
    })
    if (data.length > 1) {
      $(this.schdule_carousel_slideTarget).css("display","block");
    } else {
      $(this.schdule_carousel_slideTarget).css("display","none");
    }
    _this.refresh_group_list();
    this.get_metric_hashtags();
    _this.get_text_template_labels();
    $(".remove_button").css("display","block")
  }

  new(date = null) {
    let _this = this;
    this.update_assets = [];
    if (_this.plan == 1) {
      _this.getControllerByIdentifier("modal").open();
      return;
    }

    var param = _this.schedule_swiper.params
    _this.new_schedule_swiper.destroy( true, true );
    _this.new_schedule_swiper = new Swiper ('#schedule-carousel', _this.schedule_swiper.params);

    this.page =  0;
    this.modal_type = "new";

    this.image_counts[this.page] = 0;
    let carouselEl = $(this.schedule_carouselTarget)
    $(this.schdule_carousel_slideTarget).css("display","none");
    let template = $(".templates > .schedule-carousel-item").clone();
    $(".schedule-carousel-inner",carouselEl).empty();
    $(".schedule-carousel-inner",carouselEl).append(template);
    template.addClass("active")
    $(".schedule-carousel-inner",carouselEl).append(template)
    this.datetimepicker = $(this.published_atTargets[0]).datetimepicker({
    })
    this.create_preview_thumnail(this.page);
    this.preview_render(null, 0);
    this.file_input_render(this.initial_image_max, 0 )
    if ( typeof date == "string") {
      $(this.published_atTargets[0]).val(date + " 17:00")
    } else {
      $(this.published_atTargets[0]).val(new Date().toISOString().slice(0, 10) + " 17:00")
    }
    this.publish_errors = [];
    this.usertags[0] = []
    this.modal_open();
    this.refresh_group_list();
    this.get_metric_hashtags();
    this.get_text_template_labels();

    $(".remove_button").css("display","none")
  }

  create_preview_thumnail(page = 0){
    const _this = this;
    let previousDataArray = [];

    const sortable = Sortable.create(document.getElementsByClassName('publish_images')[page], {
      animation: 150,
      draggable: '.publish_image',
      onStart: () => {
        previousDataArray = this.get_media_srcs_array(page)
      },
      onSort: (event) => {
        const carouselEl = $('.preview-images.carousel.slide')[page];
        const publishImages = $('.publish_images')[page];
        const newMediaSrcsArray = this.get_media_srcs_array(page)
        const inner = $(".preview-carousel-inner", carouselEl)
        const indicator = $(".preview-carousel-indicators", carouselEl);

        // ソートされた場合にユーザータグを入れ替えるようにする
        try {
          const usertags = _this.get_usertags;
          const reorderedUsertags = newMediaSrcsArray.map(id => usertags[previousDataArray.indexOf(id)]);
          this.usertags[this.page] = reorderedUsertags;
        } catch (e) {
        }

        $(".preview-carousel-inner, .preview-carousel-indicators", carouselEl).empty();
        $(".thumbnail", publishImages).each(function(i, el) {
          if ($(el).attr("src")) {
            const is_active = i == 0 ? "active" : "";
            if ($(el).attr("data-type").split("/")[0] == "video") {
              inner.append(`<div class="preview-carousel-item carousel-item ${is_active}" data-action="click->schedule#open_user_tag_modal"><video playsinline autoplay muted loop src="${$(el).attr("src")}" data-page="${page}" data-itemno="${i}" /></div>`);
            } else {
              inner.append(`<div class="preview-carousel-item carousel-item ${is_active}" data-action="click->schedule#open_user_tag_modal"><img src="${$(el).attr("src")}" data-page="${page}" data-itemno="${i}" /></div>`);
            }
            indicator.append(`<li data-target="#peview-carousel-${page}" data-slide-to="${i}" class="${is_active}">`);
          }
        })
        let prevNexTemplate = $(".templates > .preview-control").clone();
        $(".preview-carousel-inner").append(prevNexTemplate)
      }
    });

  }

  validate() {
    let _this = this;
    if ($(_this.published_atTargets[_this.page]).val() == null || $(_this.published_atTargets[_this.page]).val() == ""){
      let noticeController = _this.getControllerByIdentifier("notice")
      noticeController.warning("投稿日時がありません");
      return false;
    }
    return true;
  }

  input_reset(ele) {
    let _this = this;
    if ($(ele.target).is(':checked')) {
      if (this.kind_number == $(ele.target).attr("id")){
        $(ele.target).prop("checked", false);
        this.kind_number = null
      } else {
        this.kind_number = $(ele.target).attr("id")
      }
    }
  }

  async find_post_copy(){
    await navigator.clipboard.writeText($('#schedule_body').val());
    $('#find_post_copy').text('✓コピーしました').css('opacity', '0.5');
    setTimeout(() => {
      $('#find_post_copy').text('コピー').css('opacity', '1');
    }, 2000)
  }
  
  get_input_hashtags(page) {
    let hashtags = $(this.bodyTargets[page]).val().match(/[#＃][^\s#＃]+/gm);
    const countTagsTarget = $(this.input_count_tagsTargets[page]);

    if (hashtags != null) {
      countTagsTarget.text(hashtags.length);

      if (hashtags.length >= 31) {
        countTagsTarget.parent().css('color', '#d93616');
      } else {
        countTagsTarget.parent().css('color', '');
      }
      this.is_disabled_submit_button();
      return hashtags;
    }
    return []
  }

  disp_count(ele) { 
    let html = $(".hidden_count")
    let position = $(ele.target).position()
    let count = this.get_number_roughly($(ele.target).attr("data-count"))
    html.text(count + "件")
    html.css("top", position.top - 5)
    html.css("left", position.left + 120)
    // html.css("display","block")
    // タグが取得できないので非表示にする
  }

  hidden_count(ele) {
    $(".hidden_count").css("display", "none")
  }

  disp_hashtag(page) {
    let _this = this
    let hashtags = this.get_input_hashtags(page)
    $(_this.tagsTargets[page]).empty();
    for(let i = 0 ; i < 30; i++) {
      $(_this.tagsTargets[page]).append('<span class="no_select">#</span>')
    }
    hashtags.forEach(function(element) { 
      let html = $($(".display_tags > .select_tag")[page]).clone();
      let tags = $(_this.tagsTargets[page])
      html.text(element)
      tags.prepend(html)
      $(".no_select", tags).last().remove();
    })
    this.disp_count_tags()
  }

  tag_pickup(ele) {
    let _this = this
    let kind = $(ele.target).attr("data-type")
    let select_area = $(".select_area", $(ele.target).parent().parent())
    let selected_metric_hashtag_id = $(ele.target).closest('.tag_select').find('.metric_hashtag_select').val();

    if (!selected_metric_hashtag_id && (kind == 1 || kind == 3)) {
      alert("ハッシュタグを選択してください");
    } else {
      $.ajax({
        url: "/client/api/v1/hashtags/pickup_tags?t=" + new Date().getTime(),
        type: 'get',
        data: {kind: kind, sns_url_id: this.selected_sns_url.id, metric_hashtag_id: selected_metric_hashtag_id},
        success: function (data) {
          select_area.empty();
          data.forEach(function(ele) {
            let html = '<div class="tag_list" data-count="'+ ele.posts_count + '" data-action="mouseover->schedule#disp_count mouseout->schedule#hidden_count click->schedule#edit_tag">'+ ele.name +'</div>'
            select_area.append(html)
          })
          _this.selected_tag_add_class()
        }
      })
    }
  }

  get_metric_hashtags(){
    $.ajax({
      url: "/client/api/v1/schedules/get_metric_hashtags",
      type: 'get',
      data: {sns_url_id: this.selected_sns_url.id},
      success: function (data) {
        // 1. .metric_hashtag_select の要素を取得
        const selectElement = $('.metric_hashtag_select');
        
        // 2. プルダウンの中身を初期化
        selectElement.empty();

        // 3. デフォルトのオプションを追加
        selectElement.append('<option value="">選択してください</option>');

        // 4. データをループしてオプションを追加
        data.forEach(item => {
          const option = `<option value="${item.id}">${item.name}</option>`;
          selectElement.append(option);
        });
      }
    })
  }

  edit_tag(ele) {
    let text = "#"+ $(ele.target).text()
    let tags = $(this.tagsTargets[this.page])
    let body = $(this.bodyTargets[this.page])
    if ($(this.schedule_statusTargets[this.page]).val() == 1) return;

    if ($(ele.target).hasClass("tag_selected")) {
      $(".select_tag", tags).filter(function() {
        return $(this).text() === text
      }).remove();
      $(ele.target).removeClass("tag_selected")
      let regexp = new RegExp('[\r\n\r\n]?[#＃]' + text.replace("#", "") + "([\r\n\r\n\s\]|[#【】\[\]])?")
      body.val(body.val().replace(regexp, "$1"))
    } else {
      if ($(".select_tag",tags).length >= 30) return
      $(ele.target).addClass("tag_selected")
      this.add_tag(text)
    }
    this.preview_render(this.page)
  }

  add_tag(tag_text) {
    let html = $($(".display_tags > .select_tag")[this.page]).clone();
    let tags = $(this.tagsTargets[this.page])
    let body = $(this.bodyTargets[this.page])
    html.text(tag_text)
    tags.prepend(html)
    $(".no_select", tags).last().remove()
    if (this.get_input_hashtags(this.page).indexOf(tag_text) == -1) {
      body.val(body.val() + "\n" + tag_text + "")
    }
  }

  disp_count_tags() {
    let tags = $(this.tagsTargets[this.page])
    let count = $(".select_tag", tags).length
    $(this.count_tagsTargets[this.page]).text(count)
  }

  selected_tag_add_class() {
    let hashtags = this.get_input_hashtags(this.page)
    let big_tag = $(".select_area", $($(".big_tag")[this.page]))
    let recommend_tag = $(".select_area", $($(".recommend_tag")[this.page]))
    let soars_tag = $(".select_area", $($(".soars_tag")[this.page]))
    $.each([big_tag, recommend_tag,soars_tag],function(index, ele) {
      $(".tag_list", ele).filter( function() {
        return hashtags.indexOf("#"+$(this).text()) != -1
      }).addClass("tag_selected")
      $(".tag_list", ele).filter( function() {
        return hashtags.indexOf("#"+$(this).text()) == -1
      }).removeClass("tag_selected")
  
    })
  }

  auto_select_tags() {
    let _this = this;
    let remaining_tag = 30 - $(this.count_tagsTargets[this.page]).text()
    let shuffle = function() {return Math.random()-.5};

    let big_tags = $(".select_area .tag_list", $($(".big_tag")[this.page])).filter(function() { 
      return $(this).hasClass("tag_selected") == false
    }).sort(shuffle)
    let recommend_tags = $(".select_area .tag_list", $($(".recommend_tag")[this.page])).filter(function() { 
      return $(this).hasClass("tag_selected") == false
    }).sort(shuffle)
    let soars_tags = $(".select_area .tag_list", $($(".soars_tag")[this.page])).filter(function() { 
      return $(this).hasClass("tag_selected") == false
    }).sort(shuffle)
    big_tags.each(function(index,ele) { 
      let remaining = Math.floor(remaining_tag / 3) 
      if (remaining <= index) return;
      _this.add_tag("#"+$(ele).text())
    })
    recommend_tags.each(function(index,ele) { 
      let remaining = Math.floor(remaining_tag / 3) 
      if (remaining <= index) return;
      _this.add_tag("#"+$(ele).text())
    })
    soars_tags.each(function(index,ele) { 
      let remaining = Math.floor(remaining_tag / 6) 
      if (remaining <= index) return;
      _this.add_tag("#"+$(ele).text())
    })
    this.preview_render(this.page)
    this.selected_tag_add_class()
  }

  get_number_roughly(number){
    var NumberRoughly = number;
    if(number>=1000000){
        NumberRoughly = (number/100000^0)/10 + "M";
    }else if(number>=1000){
        NumberRoughly = (number/100^0)/10 + "K";
    }
    return NumberRoughly;
  }

  async list_type_change(event){
    this.refresh_group_list();
    await this.validate_image_and_video_later();
  }

  async instagram_preview(event) {
    let _this = this;
    let instagram_preview_modalController = this.application.getControllerForElementAndIdentifier(
      this.instagram_preview_modalTarget,
      "modal"
    );
    let list_type = $(event.currentTarget).data("list-type");
    let is_all_list_type = $(event.currentTarget).data("is-all-list-type");
    const data = await _this.instagram_preview_refresh(list_type, is_all_list_type);
    instagram_preview_modalController.open();
  }

  instagram_preview_refresh(list_type = 0, is_all_list_type = false) {
    let _this = this;
    let instagram_preview_modalController = this.application.getControllerForElementAndIdentifier(
      this.instagram_preview_modalTarget,
      "modal"
    );
    let date = dayjs(_this.calendar.getDate()).format("YYYY-MM-DD");
    return $.ajax({
      type: "GET",
      url: "/client/api/v1/schedules/get_month_events",
      data: {date:date, sns_url_id: _this.selected_sns_url.id, list_type: (list_type == null) ? null : list_type, is_all_list_type: is_all_list_type},
      success: (data) => {
        instagram_preview_modalController.append_instagram_preview_data(data, list_type)
      }
    })
  }
  
  remove_confirm() {
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_modalTarget,
      "modal"
    );
    delete_modal.open();
  }
  remove_confirm_close() {
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_modalTarget,
      "modal"
    );
    delete_modal.close();
  }

  close_confirm() {
    let close_modal = this.application.getControllerForElementAndIdentifier(
      this.close_modalTarget,
      "modal"
    );
    close_modal.open();
  }
  close_confirm_close() {
    let close_modal = this.application.getControllerForElementAndIdentifier(
      this.close_modalTarget,
      "modal"
    );
    close_modal.close();
  }


  delete_memo_confirm(event) {
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_memo_modalTarget,
      "modal"
    );
    this.delete_memo_target = event.target;
    delete_modal.open();
  }
  delete_memo_confirm_close() {
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_memo_modalTarget,
      "modal"
    );
    delete_modal.close();
  }


  schedule_modal_close() {
    this.modal_close();
    this.close_confirm_close();
    this.page = 0;
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  modal_open() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
    modalController.open();

    
  }
  modal_close() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
    modalController.close();

    this.usertags = [];
    this.element.usertags.usertags = [];
    this.text_template_select_close()
  }

  select_file_modal_open(event) {
    let _this = this;
    const library = this.element.library;
    
    $(".select_upload").each((i, el) => {
      _this.close_file_select(el);
    })
    library.select_file_modal_open(event.target)
  }

  change_feed_reel_tab(event) {
    this.selected_list_type = $(event.currentTarget).data("list-type");
    this.calendar_refetch();
    this.element.scheduleGroup.refresh_all();
    this.element.scheduleGroup.check_clear();

    this.switch_display_schedule_group_list();
  }

  open_user_tag_modal(event) {
    let usertags = this.element.usertags;
    // console.log("event.target", event.target)
    let itemno = $(event.target).data("itemno");
    // console.log("page", this.usertags[this.page])
    // console.log("itemno", this.usertags[this.page][itemno])
    // console.log("event★", event)
    usertags.modal_open(event, this.usertags[this.page][itemno].concat());
  }

  usertag_save() {
    let itemno = this.element.usertags.current_itemno;
    // console.log("これなあに", itemno)
    this.usertags[this.page][itemno] = this.element.usertags.get_usertags;
    $(".preview-carousel-item img[data-page="+ this.page +"][data-itemno="+ itemno +"]").next().text(`タグ　${this.usertags[this.page][itemno].length}件`);
  }

  add_schedule_group_content(event, schedule_group_id="", group_policy="", image_policy="",  color="") {
    let _this = this;
    let checked = (this.select_schedule_group_id[this.page] == schedule_group_id) ? "checked" : "";
    let number_count = (_this.schedule_group_list_number + 1);
    let html = `<tr data-schedule_group_id=${schedule_group_id} data-schedule_group_color=${color}>
      <td class="number">
        <div class="input_checkbox">
          <input type="radio" name="schedule[schedule_group_id]" value="${schedule_group_id}" data-target="schedule_group_id"  data-action="click->schedule#group_checkbox_click" ${checked} ${checked && `style="background-color:${color}"`}>
        </div>
        <div class="number_count">${number_count}</div>
      </td>
      <td class="group_policy">${group_policy}</td>
      <td class="image_policy">${image_policy}</td>
    </tr>
    `

    if (this.schedule_group_list_number > 15) return;
    $("tbody",$(".schedule_group_select_table")[this.page]).append(html);
  }

  group_checkbox_click(event) {
    $(event.target).prop("checked", true);
    // $("tr", $(event.target).parents("tbody")).css("background-color", "#fff");
    $("input", $(event.target).parents("tbody")).each((i, el) => $(el).css("background-color", "#fff"));
    if (event.target.checked) {
      let color = $(event.target).parents("tr").data("schedule_group_color");
      $(event.target).css("background-color", color);
      $(event.target).css("background-clip","content-box");
    } 
  }

  refresh_group_list(){
    let _this = this;
    let date = dayjs(_this.element.schedule.calendar_date).format("YYYY-MM-DD");

    $.ajax({
      url: "/client/api/v1/schedules/group",
      data: {sns_url_id: this.selected_sns_url.id, list_type: this.get_modal_list_type, date: date},
      type: 'get',
      contentType: 'application/json',
      success: (data) => {
        $("tbody",$(".schedule_group_select_table")[_this.page]).empty();
        data.forEach(group => _this.add_schedule_group_content(null, group.id, group.group_policy, group.image_policy, group.color))
      }
    }) 
  }

  get get_selected_list_type() {
    let list_type = this.selected_list_typeTargets.filter(x=>x.checked);
    return $(list_type).data("list-type");
  }

  get get_modal_list_type() {
    return $("input:checked",$($(".schedule_modal .list_type")[this.page])).val();
  }
  get selected_sns_url() {
    return this.select2_url.select2("data")?.[0]
  }

  get calendar_getDate() {
    return this.calendar.getDate();
  }

  get get_usertags() {
    return this.usertags[this.page];
  }

  get schedule_group_list_number() {
    return $("tbody tr",$($(".schedule_group_select_table")[this.page])).length;
  }

  get selected_schedule_group_id() {
    let checkedRadio = $("input",$($(".schedule_group_select_table")[this.page])).filter(function() {
      return $(this).is(":checked");
    });
    return checkedRadio.attr("value");
  }

  get calendar_date() {
    return this.calendar.getDate();
  }

  formatDateTime(setDateTime) {
    const dateObject = new Date(setDateTime);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1して補完
    const day = String(dateObject.getDate()).padStart(2, '0');
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    
    const formattedDateTime = `${year}-${month}-${day}_${hours}${minutes}`;
    
    return formattedDateTime;
  }
  
  generateImagesZip(images) {
    let zip = new JSZip();
    // フォルダ作成
    const accountName = $("#select2-sns_url_id_select-container").text().trim();
    const setDateTime = $("#schedule-carousel #schedule_open_at").val();
    const formattedDateTime = this.formatDateTime(setDateTime);
    
    const folder = zip.folder(`${accountName}_${formattedDateTime}`);
  
    // フォルダ下に画像を格納
    images.forEach(image => {
      if (image.data && image.fileName) {
        folder.file(image.fileName, image.data)
      }
    });
  
    // zip を生成
    zip.generateAsync({ type: "blob" }).then(blob => {
  
      // ダウンロードリンクを 生成
      const link = document.createElement("a");
  
      // blob から URL を生成
      const dataUrl = URL.createObjectURL(blob);
      link.href = dataUrl;
      link.download = `${accountName}_${formattedDateTime}.zip`;
  
      // 設置/クリック/削除
      document.body.insertAdjacentElement("beforeEnd", link);
      link.click();
      link.remove();
  
      // オブジェクト URL の開放
      setTimeout(function() {
        window.URL.revokeObjectURL(dataUrl);
      }, 1000);
    });
  }

  async generateImagePromises(array) {
    return array.map(
      ({ url, fileName }) => new Promise((resolve) => {
        if (url != undefined) {
          fetch(url, {
            method: 'GET',
            mode: 'cors'
          })
            .then((value) => value.blob()
              .then((data) => resolve({ data, fileName })))
            .catch(() => resolve({ data: null, fileName: '' }));
        } else {
          resolve({ data: null, fileName: '' });
        }
      })
    );
  }

  async download_all_images(element) {
    const imageUrlAndNameArray = $(`#publish_images_${this.page} .image_form`).map((_, elm) => {
      let url = $(elm).children('video.thumbnail, img.thumbnail')[0];
      let fileName = "";
      if (url != undefined) {
        url = url.dataset.url;
        fileName = decodeURI(url.split("/").pop());
      }
      return { url, fileName }
    }).get();

    const imagePromises = await this.generateImagePromises(imageUrlAndNameArray);

    const images = await Promise.all(imagePromises);
    
    this.generateImagesZip(images);
  }

  removeLineBreaks(inputString) {
    return inputString.replace(/(\r|\n|\r\n)+/g, '');
  }

  text_template_select_open() {
    if ($(".select_text_template").attr("data-open") == "true") {
      this.text_template_select_close()
    } else {
      $(".select_text_template").css("display", "block");
      $(".select_text_template").attr("data-open", "true");
    }
  }

  text_template_select_close() {
    $(".select_text_template").css("display","none");
    $(".select_text_template").removeAttr("data-open");
  }

  paste_text_template(event){
    let year_month = dayjs(this.element.schedule.calendar_date).format("YYYYMM");
    let schedule_body_text = String($("#schedule_body").val());
    let checklist_common_item_id = event.target.getAttribute("data-checklist-common-item-id");

    $.ajax({
      type: 'GET',
      url: `/client/api/v1/schedules/get_schedule_body/${this.selected_sns_url.id}/${year_month < 202403 ? year_month : 999999}/${checklist_common_item_id}`,
      dataType: 'text', 
    }).then(
      (data) => {
        if (data !== "null") {
          if (!this.removeLineBreaks(schedule_body_text).includes(this.removeLineBreaks(data))) {
            $("#schedule_body").val(schedule_body_text + '\n' + data);
            this.preview_render();
          } else {
            alert("定型文はすでに入力されています")
          }
        } else {
          alert("定型文は登録されていません")
        }
        this.text_template_select_close();
      }
    ).catch(err => alert(err.message));
  }

  async modify_memos_modal_open() {
    let _this = this;
    let modify_memos_modalController = this.application.getControllerForElementAndIdentifier(
      this.modify_memos_modalTarget,
      "modal"
    );

    await _this.modify_memos_refresh();
    modify_memos_modalController.open();
  }

  modify_memos_refresh() {
    let _this = this;
    let modify_memos_modalController = this.application.getControllerForElementAndIdentifier(
      this.modify_memos_modalTarget,
      "modal"
    );

    let year_month = dayjs(this.element.schedule.calendar_date).format("YYYYMM");
    return $.ajax({
      type: "GET",
      url: "/client/api/v1/schedules/get_modify_task/"+this.selected_sns_url.id+"/"+year_month,
      success: (data) => {
        modify_memos_modalController.append_modify_memos_data(data, year_month)
      }
    })
  }

  handle_day_render(holodayData) {
    // 祝日であったらholidayクラスを追加
    holodayData.forEach(targetDate => {
      let elements = $(`td.fc-day-top[data-date="${targetDate}"]`);
      elements.addClass("holiday");
    });
  }

  get_spelling_check_words() {
    $.ajax({
      type: 'GET',
      url: `/client/api/v1/schedules/spelling_check/${this.selected_sns_url.id}`,
    }).then(
      (data) => {
        this.spelling_check_words = data;
      }
    ).catch(err => alert(err.message));
  }

  disp_warning_line(index) {
    // adminのスペルチェックで設定してある誤ワードが入力された時
    let text = this.previewBodyTextTarget.innerHTML;
    let words = this.spelling_check_words;

    if (Array.isArray(words)) {
      words.forEach((item) => {
        if (text.includes(item.incorrect_word)) {
          const regex = new RegExp(item.incorrect_word, 'g');
          text = text.replace(regex, `<span class="underline">${item.incorrect_word}</span>`);
          this.previewBodyTextTarget.innerHTML = text;
        }
      });
    } else {
      console.error("data is not an array");
    }

    // ハッシュタグに大文字があった時
    let hashtags = $(this.bodyTargets[index]).val().match(/[#＃][^\s#＃]+/gm);

    if (Array.isArray(hashtags)) {
      hashtags.forEach((tag) => {
        if (/[A-ZＡ-Ｚ]/.test(tag)) {
            const regex = new RegExp(tag, 'g');
            text = text.replace(regex, `<span class="underline">${tag}</span>`);
            this.previewBodyTextTarget.innerHTML = text;
        }
      });
    }
  }

  switch_font_family() {
    // Windowsの時だけnoto color emojiのフォントを適用すると、半角数字と#*が変に表示されるため対応
    if (this.previewBodyTextTarget.classList.contains('is-windows')) {
      let text = this.previewBodyTextTarget.innerHTML;

      // 半角数字と#*をマッチする正規表現
      const symbolRegex = /[\d#*]+/g;
  
      text = text.replace(symbolRegex, match => {
        return `<span class="sans-serif">${match}</span>`;
      });
      this.previewBodyTextTarget.innerHTML = text;
    }
  }

  location_information_search_modal_open() {
    $.ajax({
      type: "GET",
      url: "/client/api/v1/schedules/get_location_information",
      data: {
        sns_url_id: this.selected_sns_url.id,
      }
    })
    .done(
      (data) => {
        const $ul = $('.location_select_wrapper ul');
        $ul.empty();
        $ul.css("display", "block");
        const $noResultText = $('.location-search-no-result')
        $noResultText.css("display", "none");

        if (data.length > 0) {
          data.forEach((item) => {
            const $li = $('<li>');
            const $a = $('<a>', {
              'data-id': item.id,
              'data-action': 'click->schedule#select_location',
            }).text(item.location_name);
            $li.append($a);
            $ul.append($li);
          });
        } else {
          $ul.css("display", "none");
          $noResultText.css("display", "block");
        }
      }
    )
    .fail(function(jqXHR, textStatus, errorThrown) {
      alert("問題が発生しました。");
      console.log(jqXHR);
      console.log(textStatus);
      console.log(errorThrown);
    });

    let location_information_search_modalController = this.application.getControllerForElementAndIdentifier(
      this.location_information_search_modalTarget,
      "modal"
    );

    location_information_search_modalController.open();
  }

  location_information_search_modal_close() {
    let close_modal = this.application.getControllerForElementAndIdentifier(
      this.location_information_search_modalTarget,
      "modal"
    );
    close_modal.close();
  }

  select_location(event) {
    let _this = this;
    const clickedLink = event.currentTarget;
    $(_this.location_nameTargets[this.page]).val(clickedLink.textContent.trim());
    $(_this.location_idTargets[this.page]).val(clickedLink.getAttribute('data-id'));

    this.location_information_search_modal_close();
  }

  reset_location_information() {
    let _this = this;
    $(".location_name_text").text("")
    $(_this.location_nameTargets[this.page]).val("");
    $(_this.location_idTargets[this.page]).val("");
  }

  click_preview_tab() {
    this.thumbnail_tabTargets[this.page].classList.add("inactive");
    this.thumbnail_tabTargets[this.page].classList.remove("active");

    this.preview_tabTargets[this.page].classList.add("active");
    this.preview_tabTargets[this.page].classList.remove("inactive");

    this.preview_contentTargets[this.page].style.display = "block";
    this.thumbnail_contentTargets[this.page].style.display = "none";
  }

  click_thumbnail_tab() {
    this.thumbnail_tabTargets[this.page].classList.add("active");
    this.thumbnail_tabTargets[this.page].classList.remove("inactive");

    this.preview_tabTargets[this.page].classList.add("inactive");
    this.preview_tabTargets[this.page].classList.remove("active");

    this.preview_contentTargets[this.page].style.display = "none";
    this.thumbnail_contentTargets[this.page].style.display = "flex";
  }

  thumbnail_set_modal_open() {
    const videoElement = this.thumbnail_videoTargets[this.page];

    if (videoElement) {
      const src = videoElement.getAttribute("src");
      const videoWrapper = document.querySelector(".modal-content-video");
      videoWrapper.innerHTML = '';

      if (src) {
        const newVideoElement = document.createElement("video");
        newVideoElement.setAttribute("src", src);
        newVideoElement.setAttribute("id", "thumbnail_set_video");
        newVideoElement.setAttribute("controls", true); // 必要に応じてコントロールを追加
        newVideoElement.currentTime = videoElement.currentTime;

        videoWrapper.appendChild(newVideoElement);
      }
    }

    let thumbnail_set_modalController = this.application.getControllerForElementAndIdentifier(
      this.thumbnail_set_modalTarget,
      "modal"
    );

    thumbnail_set_modalController.open();
  }

  thumbnail_set_modal_close() {
    let close_modal = this.application.getControllerForElementAndIdentifier(
      this.thumbnail_set_modalTarget,
      "modal"
    );
    close_modal.close();
  }

  get_thumbnail_seconds(event) {
    let _this = this;
    const video = document.getElementById("thumbnail_set_video");
    const currentTimeMilliseconds = Math.floor(video.currentTime * 1000); // ミリ秒に変換

    this.thumbnail_videoTargets[this.page].currentTime = video.currentTime;
    $(_this.thumbnail_secondsTargets[this.page]).val(currentTimeMilliseconds)

    $(this.thumbnail_video_wrapperTargets[this.page]).css("display","flex");
    $(this.thumbnail_image_wrapperTargets[this.page]).css("display","none");

    this.thumbnail_set_modal_close();
  }

  set_thumbnail_image() {
    $(this.thumbnail_secondsTargets[this.page]).val(-1); // 画像をサムネイルにする場合は「-1」にする
    $(this.thumbnail_video_wrapperTargets[this.page]).css("display","none");
    $(this.thumbnail_image_wrapperTargets[this.page]).css("display","flex");
  }

  image_crop_modal_open(event) {
    let image_crop_modalController = this.application.getControllerForElementAndIdentifier(
      this.image_crop_modalTarget,
      "modal"
    );

    // publish_imageの要素を取得
    const publishImagesContainer = $(this.publish_imagesTargets[this.page]);
    const publishImages = publishImagesContainer.find('.publish_image');
    
    // クリックされた画像のインデックスを取得
    const clickedElement = event.currentTarget;
    const publishImageElement = clickedElement.closest(".publish_image");
    const index = publishImages.index(publishImageElement);

    this.image_crop_index = index;

    this.display_crop_image(publishImages, index); // トリミングモーダルに表示

    this.set_init_cropper(); // トリミング用の枠

    // cropperの枠の読み込みが行われるため、1秒遅らせて発火
    setTimeout(() => {
      image_crop_modalController.open();
    }, 1500);
  }

  display_crop_image(images, index) {
    const selectedImage = images[index].querySelector("img");
    if (selectedImage) {
      // const imgSrc = selectedImage.getAttribute("src");
      const originalSrc = selectedImage.getAttribute("data-original-url");
      $('.crop_image img').attr('src', originalSrc);
      $('.crop_frame_buttons button img').each(function(index, img) {
        $(img).attr('src', originalSrc);
      });
    } else {
      alert("問題が発生しました。リロードしてやり直してください")
    }
  }

  image_crop_modal_close() {
    this.image_crop_index = null;

    let close_modal = this.application.getControllerForElementAndIdentifier(
      this.image_crop_modalTarget,
      "modal"
    );
    close_modal.close();
  }

  set_init_cropper() {
    // Cropperインスタンスが既に存在する場合は破棄
    if (this.cropper) {
      this.cropper.destroy();
    }

    // Cropper.jsの初期化
    this.cropper = new Cropper(this.sketch_imageTarget, {
      aspectRatio: 1, // デフォルトは正方形
      preview: this.image_canvasTarget,
      viewMode: 2,
      zoomable: false,
      minContainerWidth: 400,
      minContainerHeight: 300,
    });
  }

  set_clear_cropper() {
    this.cropper.clear();
  }

  set_square_cropeer() {
    if (this.cropper) {
      this.cropper.setAspectRatio(1); // 正方形
      this.cropper.crop();
    }
  }

  set_vertical_4_5_cropeer() {
    if (this.cropper) {
      this.cropper.setAspectRatio(4 / 5); // 4:5
      this.cropper.crop();
    }
  }

  set_vertical_3_4_cropeer() {
    if (this.cropper) {
      this.cropper.setAspectRatio(3 / 4); // 3:4
      this.cropper.crop();
    }
  }

  set_vertical_9_16_cropeer() {
    if (this.cropper) {
      this.cropper.setAspectRatio(9 / 16); // 9:16
      this.cropper.crop();
    }
  }

  set_vertical_3_2_cropeer() {
    if (this.cropper) {
      this.cropper.setAspectRatio(3 / 2); // 3:2
      this.cropper.crop();
    }
  }

  handle_click_crop_save() {
    if (this.cropper) {
      const data = this.cropper.getData();
      this.save_crop_x_y_w_h({ x: data.x, y: data.y, w: data.width, h: data.height }); // トリミングのxywhを成形
    }
  }

  save_crop_x_y_w_h(cropData={}, isShowAlert=false) {
    // 該当のインデックスの画像のdata-crop-x-y-w-hにcropDataを追加
    let publishImageContainer = $(this.publish_imagesTargets[this.page]).find('.publish_image').eq(this.image_crop_index);
    let cropDataElement = publishImageContainer.find('.crop_x_y_w_h');
    cropDataElement.attr('data-x-y-w-h', JSON.stringify(cropData));

    // scheduleAssetIdとscheduleIdを元に、schedule_assetsテーブルのcrop_x, y, w, hを更新
    let scheduleAssetId = publishImageContainer.find('input[type="file"]').data('id');
    let scheduleId = $(this.event_idTargets[this.page]).val();

    // 外部スコープのthisを保存
    const self = this;

    $.ajax({
      url: "/client/api/v1/schedules/image_crop",
      type: 'post',
      data: {
        schedule_id: scheduleId, 
        schedule_assets_id: scheduleAssetId,
        crop_data: cropData
      },
      success: function (data) {
        self.crop_preview_image(cropData, self.image_crop_index);
        self.image_crop_modal_close();
        if (isShowAlert) alert("元の画像に戻すために、一度保存してください")
      },
      error: function (error) {
        alert("問題が発生しました。もう一度やり直してください")
      }
    });
  }

  // トリミングモーダルが閉じた後に、投稿編集モーダルのプレビューの該当の画像をトリミングされたサイズにする
  crop_preview_image(crop_x_y_w_h, cropped_image_index){
    let image_crop_x = crop_x_y_w_h["x"];
    let image_crop_y = crop_x_y_w_h["y"];
    let image_crop_w = crop_x_y_w_h["w"];
    let image_crop_h = crop_x_y_w_h["h"];
    let image_original_width = null;
    let image_original_height = null;

    // .preview_contentの該当の画像の要素を取得
    const previewImages = $(this.preview_contentTargets[this.page]).find('.preview-carousel-item');
    const previewImage = previewImages[cropped_image_index].querySelector("img");

    if (previewImage) {
      // プレビューにすでにトリミングされた画像が表示されている場合に備え、.publish_imageからトリミング無しの元画像のURLを取得
      const publishImages = $(this.publish_imagesTargets[this.page]).find('.publish_image');
      const selectedImage = publishImages[cropped_image_index].querySelector("img");
      const selectedOriginalSrc = selectedImage.getAttribute("data-original-url");

      const img = new Image();
      img.src = selectedOriginalSrc;

      img.onload = () => {
        // プレビューの該当の画像を元画像に変更（object_view_boxでトリミングして表示するため）
        previewImage.src = selectedOriginalSrc;

        // 元画像のwidth、heightを取得
        image_original_width = img.width;
        image_original_height = img.height;

        // トリミングモーダルで設定したサイズにobject_view_boxでトリミング
        previewImage.style.objectViewBox = `inset(${image_crop_y}px ${image_original_width-(image_crop_x+image_crop_w)}px ${image_original_height-(image_crop_y+image_crop_h)}px ${image_crop_x}px)`
      };

    } else {
      alert("問題が発生しました。リロードしてやり直してください")
    }
  }

  get_text_template_labels(){
    $.ajax({
      url: "/client/api/v1/checklists/text_templates",
      type: 'get',
      data: { sns_url_id: this.selected_sns_url.id },
      success: function (data) {
        const container = document.querySelector('.select_text_template');

        if (!data || data.length === 0) {
          const spanTag = document.createElement('span');
          spanTag.textContent = '定型文が登録されていません。チェックリストから定型文を登録できます！';
          container.appendChild(spanTag);
          return;
        }

        data.forEach(item => {
          const aTag = document.createElement('a');
          aTag.classList.add('content_library');
          aTag.textContent = item.description;
          aTag.setAttribute('data-checklist-common-item-id', item.checklist_common_item_id);
          aTag.setAttribute('data-action', 'click->schedule#paste_text_template'); 
          container.appendChild(aTag);
        });
      },
      error: function (error) {
      }
    });
  }

  switch_display_schedule_group_list() {
    if ($('#feed_reel_tabchange_2').is(':checked')) {
      $("#schedule_group_list").hide();
    } else {
      $("#schedule_group_list").show();
    }
  }

  display_publish_errors(publish_error_message, schedule_error_logs, i) {
    let _this = this;
    const itemsToPush = [publish_error_message];
    if (schedule_error_logs) {
      itemsToPush.push(schedule_error_logs.log);
    }
    _this.publish_errors[i].push(...itemsToPush);

    if (_this.publish_errors[i]) {
      let errorMessages = _this.publish_errors[i].join('\n');
      $('.publish_errors_wrapper')[i].innerHTML += errorMessages.replace(/\n/g, '<br>');
    }
  }

  get_media_srcs_array(page) {
    const array = $($('.publish_images')[page]).find('.image_form img, .image_form video').map((_, element) => {
      if ($(element).is('img')) {
        const src = $(element).attr('src');
        return src ? src : null;
      } 
      else if ($(element).is('video')) {
        const src = $(element).attr('src');
        return src ? src : null;
      }
    }).get().filter(src => src !== null);
    return array;
  }
}